import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { SuspenseLoading } from "./Component/Loading";
// import Main from "./Component/Main";
// import Footer from "./Component/Footer";
// import Navbar from "./Component/Navbar";
// import Header from "./Component/Header";
// import Retailer from "./Component/Retailer";
// import AboutUs from "./Component/AboutUs";
// import Foreveryone from "./Component/Foreveryone";
// import OneloadBlogsnews from "./Component/OneloadBlogsnews";
// import RechargeviaRetailer from "./Component/RechargeviaRetailer/index";
// import RechargeBanking from "./Component/RechargeBanking";
// import RechargeAtm from "./Component/RechargeAtm";
// import RechargeBranch from "./Component/RechargeBranch";
// import Contact from "./Component/Contact";
// import PrivacyPolicy from "./Component/PrivacyPolicy";
// import OneloadPosition from "./Component/OneloadPosition";
// import OneloadTalent from "./Component/OneloadTalent";
// import Faq from "./Component/Faq";
// import CVpoupap from "./Component/CVpoupap";
// import BlogsEarn from "./Component/BlogsEarn";
// import NewsEarn from "./Component/NewsEarn";
// import JobEarn from "./Component/JobEarn";
// import GenComp from "./Component/GenComp";
// import BlogPage from "./Component/blogs/BlogPage";
// import BlogDetail from "./Component/blogs/BlogDetail";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import ScrollToTop from "./Component/ScrollToTop";
// import NewsDetail from "./Component/blogs/NewsDetail";

import Error from "./Component/ErrorPage";
import "./assets/css/styles.min.css";

const Main = lazy(() => import("./NewComponent/Home/Home"));
// const Main = lazy(() => import("./Component/Main"));
const Retailer = lazy(() => import("./NewComponent/ForRetailers/ForRetailer"));
const Foreveryone = lazy(() =>
  import("./NewComponent/ForEveryone/Foreveryone")
);
// const Foreveryone = lazy(() => import("./Component/Foreveryone"));
const Footer = lazy(() => import("./Component/Footer"));
const Header = lazy(() => import("./Component/Header"));
const AboutUs = lazy(() => import("./NewComponent/AboutUs/AboutUs"));
// const AboutUs = lazy(() => import("./Component/AboutUs"));
const RechargeviaRetailer = lazy(() =>
  import("./NewComponent/RechargeviaRetailer/RechargeviaRetailer")
);
// const RechargeviaRetailer = lazy(() =>
//   import("./Component/RechargeviaRetailer/index")
// );
const RechargeBanking = lazy(() => import("./Component/RechargeBanking"));
const RechargeAtm = lazy(() => import("./Component/RechargeAtm"));
const RechargeBranch = lazy(() => import("./Component/RechargeBranch"));
const Contact = lazy(() => import("./NewComponent/ContactUs/ContactUs"));
// const Contact = lazy(() => import("./Component/Contact"));
const PrivacyPolicy = lazy(() =>
  import("./NewComponent/PrivacyPolicy/PrivacyPolicy")
);
// const PrivacyPolicy = lazy(() => import("./Component/PrivacyPolicy"));
const OneloadPosition = lazy(() => import("./NewComponent/Jobs/JobsPage"));
// const OneloadPosition = lazy(() => import("./Component/OneloadPosition"));
const OneloadTalent = lazy(() => import("./NewComponent/Jobs/JobApplyPage"));
// const OneloadTalent = lazy(() => import("./Component/OneloadTalent"));
const Faq = lazy(() => import("./NewComponent/FAQ/Faq"));
// const Faq = lazy(() => import("./Component/Faq"));
const CVpoupap = lazy(() => import("./Component/CVpoupap"));
const BlogsEarn = lazy(() => import("./Component/BlogsEarn"));
const NewsEarn = lazy(() => import("./Component/NewsEarn"));
const JobEarn = lazy(() => import("./NewComponent/Jobs/JobEarn"));
// const JobEarn = lazy(() => import("./Component/JobEarn"));
const GenComp = lazy(() => import("./Component/GenComp"));
const BlogPage = lazy(() => import("./NewComponent/Blogs/BlogPage"));
// const BlogPage = lazy(() => import("./Component/blogs/BlogPage"));
const BlogDetail = lazy(() => import("./NewComponent/Blogs/BlogDetail"));
// const BlogDetail = lazy(() => import("./Component/blogs/BlogDetail"));
const ScrollToTop = lazy(() => import("./Component/ScrollToTop"));
const NewsDetail = lazy(() => import("./NewComponent/Blogs/NewsDetail"));
// const NewsDetail = lazy(() => import("./Component/blogs/NewsDetail"));

const App = () => {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <ScrollToTop>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/Footer" exact component={Footer} />
            <Route path="/Home" exact component={Main} />
            {/* <Route path="/Home" exact component={Navbar} /> */}
            <Route path="/Retailer" exact component={Retailer} />
            <Route path="/Aboutus" exact component={AboutUs} />
            <Route path="/Foreveryone" exact component={Foreveryone} />
            <Route
              path="/Rechagevriaretailer"
              exact
              component={RechargeviaRetailer}
            />
            <Route path="/Rechargebanking" exact component={RechargeBanking} />
            <Route path="/RechargeAtm" exact component={RechargeAtm} />
            <Route path="/Rechargebranch" exact component={RechargeBranch} />
            <Route path="/Contact" exact component={Contact} />
            <Route path="/Privacypolicy" exact component={PrivacyPolicy} />
            <Route path="/blogs" exact component={BlogPage} />
            <Route path="/blog-detail/:blogID" component={BlogDetail} />

            <Route path="/Jobs" exact component={OneloadPosition} />
            <Route path="/JobsApply" exact component={OneloadTalent} />
            <Route path="/Faq" exact component={Faq} />
            <Route path="/CVpoupap" exact component={CVpoupap} />
            <Route path="/BlogsEarn" exact component={BlogsEarn} />
            <Route path="/NewsEarn" exact component={NewsEarn} />
            <Route path="/JobEarn" exact component={JobEarn} />
            <Route path="/GenComp" exact component={GenComp} />
            <Route path="/news-detail/:newsID" exact component={NewsDetail} />
            <Route exact path="*" component={Error} />
          </Switch>
          <Footer />
        </div>
      </ScrollToTop>
    </Suspense>
  );
};
export default App;
