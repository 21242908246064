import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  listLoading: false,
  actionsLoading: false,
  error: null,
  homeEntities: undefined,
  homeCustomerFeedBackSliderEntities: [],
  homeMainCardEntity: [],
  homeServiceCardEntity: [],
  homeDesEntity: [],
  whyChoseCardEntity: [],
  getReatailerCardEntity: [],
  benefitCardEntity: [],
  retailerFaqsEntity: [],
  OfficeContacttDataEntities: [],

  newsEntity: [],
  leftNewsEntity: [],
  faqsTableEntity: [],
  faqsRetailerEntity: [],
  faqsGeneralEntity: [],
  jobTableDataEntity: [],
  jobEarnEntity: [],

  retailerEntities: undefined,
  forEveryoneEntities: undefined,
  aboutUsEntities: undefined,
  contactUsEntities: undefined,
  privacyEntities: undefined,
  newsEntities: undefined,
  mainBlogsEntity: undefined,
  blogsEntity: undefined,
  jobsTableEntity: [],
  mainCity: undefined,

  faqsEntity: undefined,
  mainChargeEntity: undefined,
  desEntity: [],
  mainJobsEntity: undefined,
  termsDesEntity: [],
  privacyEntity: [],
  // blogs
  singleBlogInfoEntity: undefined,
  singleBlogEntity: undefined,
  allBlogEntities: [],
  allBlogdata: [],
  allNewsdata: [],
  //news
  singleNewsInfoEntity: undefined,
  singleNewsEntity: undefined,
  allNewsEntities: [],

  faqEntity: [],

  footerEntities: undefined,
  contactUrlEntity: [],
  emailUrlEntity: [],
  uanUrlEntity: [],
  productUrlEntity: [],
  socialUrlEntity: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const pagesSlice = createSlice({
  name: "pages",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.loading = false;
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.loading = true;
        state.listLoading = false;
      } else {
        state.actionsLoading = true;
      }
    },

    homeState: (state, action) => {
      state.loading = true;
      state.actionsLoading = false;
      state.error = null;

      state.homeEntities = action?.payload?.homeEntities;
      state.homeCustomerFeedBackSliderEntities =
        action?.payload?.homeCustomerFeedBackSliderEntities;

      state.homeMainCardEntity = action?.payload?.homeMainCardEntity;
      state.homeServiceCardEntity = action?.payload?.homeServiceCardEntity;
      state.homeDesEntity = action?.payload?.homeDesEntity;
      state.whyChoseCardEntity = action?.payload?.whyChoseCardEntity;
    },
    forEveryoneState: (state, action) => {
      //   forEveryone
      state.forEveryoneEntities = action?.payload?.forEveryoneEntities;
      state.cardEntity = action?.payload?.cardEntity;
      state.faqEntity = action?.payload?.faqEntity;
    },
    retailerState: (state, action) => {
      state.retailerEntities = action?.payload?.retailerEntities;
      state.getReatailerCardEntity = action?.payload?.getReatailerCardEntity;
      state.benefitCardEntity = action?.payload?.benefitCardEntity;
      state.retailerFaqsEntity = action?.payload?.retailerFaqsEntity;
    },
    aboutUsState: (state, action) => {
      state.aboutUsEntities = action?.payload?.aboutUsEntities;
      state.boardCardEntity = action?.payload?.boardCardEntity;
      state.investorCardEntity = action?.payload?.investorCardEntity;
      state.leaderCardEntity = action?.payload?.leaderCardEntity;
      state.coreValueEntity = action?.payload?.coreValueEntity;
      state.newsEntity = action?.payload?.newsEntity;
      state.leftNewsEntity = action?.payload?.leftNewsEntity;
    },
    footerState: (state, action) => {
      state.footerEntities = action?.payload?.footerEntities;
      state.contactUrlEntity = action?.payload?.contactUrlEntity;
      state.emailUrlEntity = action?.payload?.emailUrlEntity;
      state.uanUrlEntity = action?.payload?.uanUrlEntity;
      state.productUrlEntity = action?.payload?.productUrlEntity;
      state.socialUrlEntity = action?.payload?.socialUrlEntity;
    },
    contactUsState: (state, action) => {
      state.contactUsEntities = action?.payload?.contactUsEntities;
      state.OfficeContacttDataEntities =
        action?.payload?.OfficeContacttDataEntities;
    },
    privacyState: (state, action) => {
      state.privacyEntities = action?.payload?.privacyEntities;
      state.termsDesEntity = action?.payload?.termsDesEntity;
      state.privacyEntity = action?.payload?.privacyEntity;
    },
    newsState: (state, action) => {
      state.newsEntities = action?.payload?.newsEntities;
    },
    mainJobsState: (state, action) => {
      state.mainJobsEntity = action?.payload?.mainJobsEntity;
    },
    jobsTableState: (state, action) => {
      state.jobsTableEntity = action?.payload?.jobsTableEntity;
      state.jobTableDataEntity = action?.payload?.jobTableDataEntity.map(
        (item) => {
          return JSON.parse(item.TABLE);
        }
      );

      state.jobEarnEntity = action?.payload?.jobEarnEntity;
    },
    faqsState: (state, action) => {
      state.faqsEntity = action?.payload?.faqsEntity;
      state.faqsTableEntity = action?.payload?.faqsTableEntity;
      state.faqsRetailerEntity = action?.payload?.faqsRetailerEntity;
      state.faqsGeneralEntity = action?.payload?.faqsGeneralEntity;
    },
    mainChargeState: (state, action) => {
      state.mainChargeEntity = action?.payload?.mainChargeEntity;
    },
    retailerCityState: (state, action) => {
      state.mainCity = action?.payload?.mainCity;
    },
    // blogs slice -- start
    allBlogsState: (state, action) => {
      state.allBlogEntities = action?.payload?.allBlogEntities.map((item) => {
        return JSON.parse(item.BLOGS_CARD).pop();
      });
    },
    singleBlogState: (state, action) => {
      state.singleBlogEntity = action?.payload?.singleBlogEntity;
      state.singleBlogInfoEntity = action?.payload?.singleBlogInfoEntity;
      state.allBlogdata = action?.payload?.allBlogdata;
    },
    // blogs slice -- end

    /////////////////////news/////////////////////////////////
    allNewsState: (state, action) => {
      state.allNewsEntities = action?.payload?.allNewsEntities.map((item) => {
        return JSON.parse(item.NEWS_CARD).pop();
      });
    },
    singleNewsState: (state, action) => {
      state.singleNewsEntity = action?.payload?.singleNewsEntity;
      state.singleNewsInfoEntity = action?.payload?.singleNewsInfoEntity;
      state.allNewsdata = action?.payload?.allNewsdata;
    },
  },
});
